import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SideBar from "../components/sidebar"

const NotFoundPage = (props) => (
  
    <Layout>
    <SEO title="Couldn't find that page"/>
    <SideBar path={props.path} />
    <div className="content posts-container">
      <div className="not-found"><h1>Sorry</h1><hr className="hr-style"/>Couldn't find that page. Check the address. Maybe the page got moved?</div>
    </div>    
  </Layout>
)

export default NotFoundPage
